
import validator from 'validator';

const ValidateAddVote = (data) => {
    let errors = {};

    const { application_id } = data;

    if (validator.isEmpty(application_id)) {
        errors.application_id = 'Please Select An Applicant.';
    }
    return {
        isInvalid: Object.keys(errors).length > 0,
        error: errors,
    };
};

export default ValidateAddVote;