<template>
    <ConfirmDialog group="dialog" />
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-fluid">
                <h4>
                    <strong>{{ $t('ApplicationManagement.view.Application_Details') }}</strong>
                </h4>
                <div class="p-field p-grid">
                    <label for="name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('ApplicationManagement.view.Title')
                    }}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ title }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('ApplicationManagement.view.Description')
                    }}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ description }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('ApplicationManagement.view.Country')
                    }}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ country_name }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('ApplicationManagement.view.Max_No_of_Applicants')
                    }}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ max_user_apply }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('ApplicationManagement.view.No_of_Applicants')
                    }}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ applicantCount }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('ApplicationManagement.view.No_of_Selected_applicants')
                    }}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ selectedApplicantCount }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('ApplicationManagement.view.Application_Status')
                    }}</label>
                    <div class="p-col-12 p-md-10">
                        <span
                            v-if="cur_date > startdateformat(expire_date) && cur_date < startdateformat(voting_start_date)">
                            <p>Shortlisting Ongoing</p>
                        </span>

                        <span
                            v-else-if="cur_date >= startdateformat(voting_start_date) && cur_date <= startdateformat(voting_end_date)">
                            <p>Voting Ongoing</p>
                        </span>

                        <span
                            v-else-if="cur_date >= startdateformat(release_date) && cur_date <= startdateformat(expire_date)">
                            <p>Registration Ongoing</p>
                        </span>

                        <span v-else-if="cur_date > startdateformat(expire_date)">
                            <p>Expired</p>
                        </span>

                        <span v-else-if="cur_date < startdateformat(release_date)">
                            <p>Yet to Start</p>
                        </span>

                        <span v-else>
                            <p>--</p>
                        </span>
                        <!-- <p>{{ selectedApplicantCount }}</p> -->
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('ApplicationManagement.view.Application_Start_Date_&_Time')
                    }}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ UTCdateformat(release_date) }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('ApplicationManagement.view.Application_End_Date_&_Time')
                    }}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ UTCdateformat(expire_date) }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('ApplicationManagement.view.Voting_start_Date_&_Time')
                    }}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ UTCdateformat(voting_start_date) }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('ApplicationManagement.view.Voting_End_Date_&_Time')
                    }}</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ UTCdateformat(voting_end_date) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="p-grid p-fluid">
            <div class="p-col-12">
                <h5>{{ $t('ApplicationManagement.view.Search') }}</h5>
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-3">
                        <label for="type">{{ $t('ApplicationManagement.view.Name') }}</label>
                        <InputText id="googlurl" type="text"
                            :placeholder="$t('ApplicationManagement.view.Search_by_name')" v-model="name" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Email') }}</label>
                        <InputText id="googlurl" type="text"
                            :placeholder="$t('ApplicationManagement.view.Search_by_email')" v-model="email" />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Age_From') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                            :placeholder="$t('ApplicationManagement.view.Search_by_Age_From')" v-model="age_from" />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Age_To') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                            :placeholder="$t('ApplicationManagement.view.Search_by_Age_To')" v-model="age_to" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="type">{{ $t('ApplicationManagement.view.Gender') }}</label>
                        <Dropdown v-model="gender" :options="dropdownGenderValues" optionValue="value"
                            optionLabel="name" :placeholder="$t('ApplicationManagement.view.Select_Gender')" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Height_From') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                            :placeholder="$t('ApplicationManagement.view.Search_by_Height_From')"
                            v-model="height_from" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Height_To') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                            :placeholder="$t('ApplicationManagement.view.Search_by_Height_To')" v-model="height_to" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Weight_From') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                            :placeholder="$t('ApplicationManagement.view.Search_by_Weight_From')"
                            v-model="weight_from" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Weight_To') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                            :placeholder="$t('ApplicationManagement.view.Search_by_Weight_To')" v-model="weight_to" />
                    </div>

                    <!-- <div class="p-field p-col-12 p-md-3">
                        <label for="pass">Applicant Selection Status</label>
                        <Dropdown v-model="is_shortlisted" :options="dropdownAppStatusValues" optionValue="value"
                            optionLabel="name" placeholder="Select Status" />
                    </div> -->

                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Registration_Dates') }}</label>
                        <Calendar inputId="range" v-model="dateVotingRange" selectionMode="range" :manualInput="false"
                            placeholder="YYYY/MM/DD - YYYY/MM/DD" :showIcon="true"></Calendar>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-mt-6 p-flex-column p-flex-lg-row">
            <div class="p-mb-4 p-mb-lg-0">
                <Button :label="$t('button.today')" icon="pi pi-calendar" iconPos="left"
                    class="p-button p-button-sm p-mr-2 p-mb-2" @click="today"></Button>
                <Button :label="$t('button.lastWeek')" icon="pi pi-calendar" iconPos="left"
                    class="p-button p-button-sm p-mr-2 p-mb-2" @click="lastweek"></Button>
                <Button :label="$t('button.lastMonth')" icon="pi pi-calendar" iconPos="left"
                    class="p-button p-button-sm p-mr-2 p-mb-2" @click="lastmonth"></Button>
                <Button :label="$t('button.last6Months')" icon="pi pi-calendar" iconPos="left"
                    class="p-button p-button-sm p-mr-2 p-mb-2" @click="lastsixmonth"></Button>
                <!-- <Button :label="$t('button.lastYear')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastyear"></Button> -->
            </div>
            <div>
                <Button :label="$t('button.search')" icon="pi pi-search" iconPos="left"
                    class="p-button p-button-sm p-mr-2 p-mb-2" @click="searchApplicant()"></Button>
                <Button :label="$t('button.reset')" icon="pi pi-replay" iconPos="left"
                    class="p-button p-button-sm p-mr-2 p-mb-2" @click="resetvotemanagement()"></Button>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="p-d-flex p-jc-between p-mb-2">
                    <h4>{{ $t('ApplicationManagement.view.Applicants_List') }}</h4>
                    <div>
                        <!-- <router-link to="">
                            <Button label="Start Shortlisting" icon="pi pi-flag" iconPos="left"
                                class="p-button p-button-sm p-mr-2 p-mb-2"></Button>
                        </router-link> -->
                    </div>
                </div>
                <div v-if="Applicants.length === 0" style="display:flex; justify-content:center;">
                    <p><b>{{ $t('Nodata') }}</b></p>
                </div>
                <DataTable :value="Applicants" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                    :rowHover="true" :loading="loading1" :filters="filters1" responsiveLayout="scroll"
                    v-model:selection="selected"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[10, 25, 50]"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" v-else>
                    <ConfirmDialog group="dialog" />

                    <template #empty>{{ $t('Nodata') }}</template>
                    <template #loading>Loading data. wait.</template>
                    <!-- <template #body="{ data }"> -->
                    <!-- <column selectionMode="multiple" style="width: 16px; text-align: center"
                            v-if="cur_date >= startdateformat(expire_date) && cur_date <= startdateformat(voting_start_date)"
                        :disabledSelection="Applicants.is_shortlisted == '1'">
                        <span>svsdfv</span>
                        </column> -->
                    <column selectionMode="multiple" style="width: 16px; text-align: center"
                        v-if="cur_date >= startdateformat(expire_date) && cur_date <= startdateformat(voting_start_date)">
                        <template #body="{ data }">
                            <Checkbox inputId="binary" v-model="data.checked" :binary="true"
                                :disabled="data.is_shortlisted == '1'" :readonly="data.is_shortlisted == '1'"
                                @input="getID(data.checked, data.id)" />
                        </template>
                    </column>
                    <!-- </template> -->


                    <Column field="#" header="#" style="min-width: 5rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Sl. No.</span>
                            {{ data.serial_no }}
                        </template>
                    </Column>
                    <Column field="Applicant ID" :header="$t('ApplicationManagement.view.Applicant_ID')"
                        style="min-width: 13rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Applicant ID</span>
                            {{ data.applicant_id }}
                        </template>
                    </Column>
                    <Column field="Name" :header="$t('ApplicationManagement.view.Name')" style="min-width: 13rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Name</span>
                            {{ data.name }}
                        </template>
                    </Column>
                    <Column field="Age" :header="$t('ApplicationManagement.view.Age')" style="min-width: 12rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Age</span>
                            {{ data.age }}
                        </template>
                    </Column>
                    <Column field="Gender" :header="$t('ApplicationManagement.view.Gender')" style="min-width: 12rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Gender</span>

                            {{ data.gender === 'm' ? 'Male' : 'Female' }}
                        </template>
                    </Column>

                    <Column field="Email" :header="$t('ApplicationManagement.view.Email')" style="min-width: 12rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Email</span>
                            {{ data.email }}
                        </template>
                    </Column>

                    <Column field="Mobile Number" :header="$t('ApplicationManagement.view.Mobile_Number')"
                        style="min-width: 12rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Mobile Number</span>
                            {{ data.mobile }}
                        </template>
                    </Column>

                    <Column field="Height" :header="$t('ApplicationManagement.view.Height')" style="min-width: 13rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Height</span>
                            {{ data.height }}
                        </template>
                    </Column>

                    <Column field="Weight" :header="$t('ApplicationManagement.view.Weight')" style="min-width: 12rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Weight</span>
                            {{ data.weight === null ? '--' : data.weight }}
                        </template>
                    </Column>

                    <Column field="Country" :header="$t('ApplicationManagement.view.Country')" style="min-width: 14rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Country</span>
                            {{ data.country_name }}
                        </template>
                    </Column>
                    <Column field="Registration Date" :header="$t('ApplicationManagement.view.Registration_Dates')"
                        style="min-width: 14rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Registration Date</span>
                            {{ Regdateformat(data.registration_date) }}
                        </template>
                    </Column>
                    <Column field="Vote Count" :header="$t('ApplicationManagement.view.Vote_Count')"
                        style="min-width: 14rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Vote Count</span>
                            {{ data.vote_count }}
                        </template>
                    </Column>
                    <Column field="Selection" :header="$t('ApplicationManagement.view.Selection')"
                        style="min-width: 14rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Selection</span>
                            <!-- {{ data.is_shortlisted }} -->
                            <span v-if="data.is_shortlisted == 1">
                                <i class="pi pi-check" id="sign"></i>
                            </span>
                            <span v-else-if="data.is_shortlisted == 0">
                                <i class="pi pi-times" id="sign"></i>
                            </span>
                            <span v-else>
                                <i class="pi pi-check" disabled id="signs"></i>
                            </span>
                        </template>
                    </Column>

                    <Column field="Actions" :header="$t('ApplicationManagement.view.Action')">
                        <template #body="{ data }">
                            <span class="p-column-title">Actions</span>
                            <p style="display: none">{{ data.status }}</p>
                            <div style="display: flex">
                                <router-link :to="'/ManagerApplicationtDetails/' + data.id">
                                    <Button label="info" class="n-wrap p-button-outlined p-button-info p-mb-2">
                                        <i class="pi pi-eye p-mr-2"></i>
                                    </Button>
                                </router-link>
                                <!-- <Button :label="$t('button.delete')" icon="pi pi-trash" class="n-wrap p-button-danger p-button-outlined p-mr-2 p-mb-2" @click="confirm(data.id)" /> -->
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

    <!-- <div class="p-d-flex p-jc-end" style="float: left">

        <router-link :to="'/VoteManagementEdit/' + $route.params.id"><Button label="help"
                class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2"><i class="pi pi-user-edit p-mr-2"></i>
                Edit </Button></router-link>
         <Button @click="del($route.params.id)" label="delete" class="p-button-outlined p-button-danger p-mr-2 p-mb-2"><i class="pi pi-trash p-mr-2"></i>{{$t('button.delete')}}</Button>
    </div> -->
    <div class="p-d-flex p-jc-end" style="float: left"
        v-if="cur_date >= startdateformat(expire_date) && cur_date <= startdateformat(voting_start_date) && Applicants.length != 0">
        <Button :label="$t('Bulletins.EditBulletin.Save')" icon="pi pi-save" iconPos="left"
            class="p-button p-button-sm p-mr-2 p-mb-2" @click="openResponsive"></Button>
    </div>

    <div class="p-d-flex p-jc-end p-ai-center">
        <div>
            <Button :label="$t('button.back')" icon="pi pi-replay" iconPos="left"
                class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
        </div>
    </div>

    <Dialog header="Shortlisting" v-model:visible="displayResponsive"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }">
        <!-- <p class="text-choose"><b>{{ $t('Free1.view.ReasonSelect') }}</b></p> -->
        <div class="p-col-12 p-md-12" id="reason">
            <p>{{ $t('ApplicationManagement.Details.ModalText') }}</p>
        </div>
        <template #footer>
            <div class="p-d-flex p-jc-end p-ai-center dual-btn" style="float: right">
                <Button :label="$t('ApplicationManagement.Details.Ok')" icon="pi pi-check" id="save"
                    @click="ShortList" />
            </div>
            <div class="p-d-flex p-jc-end p-ai-center dual-btn" style="float: left">
                <Button :label="$t('ApplicationManagement.Details.Cancel')" icon="pi pi-times" @click="closeResponsive"
                    class="p-button p-button p-button-outlined" id="close" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import axios from 'axios';
import VotemanagementService from '../../../service/API/SuperAdmin/VotemanagementService.js';
import validateShortlistVote from '../../../validations/VoteManagement/validateShortlistVote';
// import {useRouter} from 'vue-router'
import moment from 'moment';
export default {
    data() {
        return {
            dropdownGenderValues: [
                { name: '남성', value: 'm' },
                { name: '여성', value: 'f' },
            ],
            dropdownGenderValue: null,
            dropdownAppStatusValues: [
                { name: 'Pending', value: '2' },
                { name: 'Approve', value: '1' },
                { name: 'Rejected', value: '0' },
            ],
            dropdownAppStatusValue: null,
            title: '',
            country_id: '',
            max_user_apply: '',
            image: '',
            description: '',
            release_date: '',
            expire_date: '',
            voting_start_date: '',
            voting_end_date: '',
            status: 'active',
            selectedApplicantCount: '',
            applicantCount: '',

            file: '',
            files: '',
            fileName: '',
            fileExtension: '',
            filesExtension: '',
            fileExtensions: '',
            filesExtensions: '',
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            name: '',
            email: '',
            age_from: '',
            age_to: '',
            gender: '',
            height_from: '',
            height_to: '',
            weight_from: '',
            weight_to: '',
            from_date: '',
            to_date: '',
            applicationStatus: '',
            registrationDate: '',
            application_id: '',
            dateVotingRange: '',
            Applicants: [],
            selected: [],
            displayResponsive: false,
            isModalVisible: false,
            serial_no: '',
            application_status: '',
            is_shortlisted: '',
            checked: true,
            // cur_date:'',
            // cur_date: new Date().toISOString().slice(0, 10),
            cur_date: moment(new Date()).format('YYYY/MM/DD HH:mm:ss'),
            // type:''
        };
    },
    created() {
        this.votemanagementService = new VotemanagementService();
    },

    mounted() {
        this.getApplicantList();
        this.votemanagementService.viewVoteManagement(this.$route.params.id).then((res) => {
            this.title = res.data.data.application[0].title;
            this.country_name = res.data.data.application[0].country_name;
            this.voting_end_date = res.data.data.application[0].voting_end_date;
            this.voting_start_date = res.data.data.application[0].voting_start_date;
            this.status = res.data.data.application[0].status;
            this.expire_date = res.data.data.application[0].expire_date;
            this.description = res.data.data.application[0].description;
            this.release_date = res.data.data.application[0].release_date;
            this.max_user_apply = res.data.data.application[0].max_user_apply;
            this.applicantCount = res.data.data.applicantCount;
            this.selectedApplicantCount = res.data.data.selectedApplicantCount;
            this.application_status = res.data.data.application[0].application_status;

            // console.log(this.cur_date);
        });
    },
    methods: {
        startdateformat(value) {
            if (value) {
                return moment.utc(value).format('YYYY/MM/DD HH:mm:ss');
            }
        },
        Regdateformat(value) {
            if (value) {
                // return moment(String(value)).format('YYYY/MM/DD ');
                return moment(String(value)).locale('ko').format('ll');
            }
        },
        formatRangeDate(date) {
            if (date === undefined || date === '') {
                return '';
            }
            return moment(new Date(date)).format('YYYY-MM-DD');
        },
        UTCdateformat(value) {
            if (value) {
                return moment.utc(value).locale('ko').format('ll - LTS');

            }
        },
        openResponsive() {
            this.displayResponsive = true;
        },
        closeResponsive() {
            this.displayResponsive = false;
        },
        getID(e, v) {
            console.log(e, v)
            if (e === true) {
                this.selected.push(v)
            }
            else {
                const index = this.selected.indexOf(v);
                if (index > -1) { // only splice array when item is found
                    this.selected.splice(index, 1); // 2nd parameter means remove one item only
                }

                // array = [2, 9]
                // console.log(this.selected)
            }
            // console.log(this.selected);


        },

        getApplicantList() {
            const ApplicantParams = {
                // page: 1,
                // limit: this.pagination.pagination_limit,
                application_id: this.$route.params.id,
                name: this.name,
                email: this.email,
                age_from: this.age_from,
                age_to: this.age_to,
                weight_from: this.weight_from,
                weight_to: this.weight_to,
                height_from: this.height_from,
                height_to: this.height_to,
                gender: this.gender,
                applicationStatus: this.applicationStatus,
                from_date: this.searchdate,
                to_date: this.searchenddate,
            };
            this.loading1 = true;
            this.votemanagementService
                .getParticularVoteApplicatList(ApplicantParams)
                .then((res) => {
                    this.Applicants = res.data.data.applicants;
                    this.totalrecords = res.data.total_records;
                    // this.is_shortlisted = res.data.data.is_shortlisted;
                    // this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                    this.loading1 = false;
                    console.log(res);
                    // console.log(this.Applicants[0].is_shortlisted)
                })
                .catch((err) => console.log(err));
        },
        addDay(val) {
            const date = new Date(val);
            var dd = date.getDate();
            var mm = date.getMonth() + 1;
            var yyyy = date.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return (val = yyyy + '-' + mm + '-' + dd);
        },

        resetvotemanagement() {
            this.name = '';
            this.email = '';
            this.age_from = '';
            this.age_to = '';
            this.height_from = '';
            this.height_to = '';
            this.weight_from = '';
            this.weight_to = '';
            this.gender = '';
            this.applicationStatus = '';
            this.dateVotingRange = '';
            // this.calendarValue1 = '';
            this.loading1 = true;
            // this.getApplicantList();
            window.location.reload();
        },
        today() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate());
            this.dateVotingRange = [firstDate, today];
            this.searchApplicant();
        },
        lastweek() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 7);
            this.dateVotingRange = [firstDate, today];
            this.searchApplicant();
        },
        lastmonth() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 31);
            this.dateVotingRange = [firstDate, today];
            this.searchApplicant();
        },
        lastsixmonth() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 182);
            this.dateVotingRange = [firstDate, today];
            this.searchApplicant();
        },
        ShortList() {
            // let xyz = [];
            let data = this.selected;
            let self = this;
            // console.log(data)
            // for (var a = 0; a < data.length; a++) {
            //     //    xyz.push({ user_id: data[a].id });
            //     xyz.push(data[a].id);
            // }
            if (data.length == 0) {
                self.$toast.add({ severity: 'error', summary: 'error', detail: '한 명 이상의 사용자를 선택하세요.', life: 3000 });
                this.displayResponsive = false;
                setTimeout(() => {
                    // window.location.reload();
                }, 1000);
            }
            // self.selectedItemss = xyz;
            let vcheckData = {
                application_id: self.$route.params.id.toString(),
                // category_id: self.category_id.toString(),
                // sorts_description: self.sorts_description,
                idArray: data,
            };
            //    this.formData.delete('notification_title');
            //     this.formData.delete('description');
            //     this.formData.delete('notification_type');
            //     this.formData.delete('user_ids_arr');
            const { isInvalid, error } = validateShortlistVote(vcheckData);
            if (isInvalid) {
                self.error = error;
                console.log(error);
            } else {
                {
                    return axios.put('/asnhist-dev/api/v1/admin/application/short_list_applicant', { idArray: data, application_id: this.$route.params.id }).then(() => {
                        this.openResponsive = false;
                        this.displayResponsive = false;
                        setTimeout(() => {
                            this.openResponsive = false;
                            this.displayResponsive = false;
                            this.$toast.add({ severity: 'success', summary: '확인.', detail: '최종 후보자 명단이 성공적으로 표시되었습니다.', life: 3000 });
                            // this.$router.push({ name: 'ManagerApplicationlistView' });
                            window.location.reload();
                        }, 1000);
                    });
                }
            }
        },

        searchApplicant() {
            if (
                this.name === '' &&
                this.email === '' &&
                this.dateVotingRange === '' &&
                this.age_from === '' &&
                this.age_to === '' &&
                this.weight_from === '' &&
                this.weight_to === '' &&
                this.height_from === '' &&
                this.height_to === '' &&
                this.applicationStatus === '' &&
                this.gender === ''
            ) {
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '검색창을 입력해주세요.', life: 2000 });
            } else {
                if (this.dateVotingRange.at(0) != '') {
                    this.searchdate = this.formatRangeDate(this.dateVotingRange.at(0));
                } else {
                    this.searchdate = '';
                }
                if (this.dateVotingRange.at(1) != '') {
                    this.searchenddate = this.formatRangeDate(this.dateVotingRange.at(1));
                } else {
                    this.searchenddate = '';
                }
                const ShortlistParams = {
                    // page: 1,
                    // limit: this.pagination.pagination_limit,
                    application_id: this.$route.params.id,
                    name: this.name,
                    email: this.email,
                    age_from: this.age_from,
                    age_to: this.age_to,
                    weight_from: this.weight_from,
                    weight_to: this.weight_to,
                    height_from: this.height_from,
                    height_to: this.height_to,
                    gender: this.gender,
                    applicationStatus: this.applicationStatus,
                    from_date: this.searchdate,
                    to_date: this.searchenddate,
                };
                this.votemanagementService
                    .getParticularVoteApplicatList(ShortlistParams)
                    .then((res) => {
                        this.Applicants = res.data.data.applicants;
                        // this.total = res.data.data.total;
                        this.loading1 = false;
                        console.log(res);
                    })
                    .catch(() => {
                        this.Applicants = [];
                        this.loading1 = false;
                    });
            }
        },
    },
};
</script>

<style scoped>
.p-fluid .p-button {
    width: auto;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

.product-image {
    padding-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    height: 100px;
    /* margin-left: 25%; */
}

i#sign {
    background: black;
    color: white;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

i#signs {
    background: grey;
    color: rgb(243, 235, 235);
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
</style>